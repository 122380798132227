<template>
  <div>
    <router-view></router-view>
  </div>
</template>



<script>
export default {
  mounted() {
  },
  components: {
  },
};
</script>
    

<style>
</style>