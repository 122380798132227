var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('page-frame',{attrs:{"reserve_nav_bar_space":false}},[_c('project-common',{attrs:{"project":_vm.$commondata.utilities.vueAlerts}},[_c('template',{slot:"title"},[_c('text-styles',{attrs:{"type":"white extra_large extrabold"}},[_vm._v("Vue-Alerts")]),_c('text-styles',{attrs:{"type":"white large extrabold y_spacing"}},[_vm._v("Get that iOS feel in your Vue 2.x project.")]),_c('ui-button-stack',{staticClass:"mt-10"},[_c('ui-button',{attrs:{"asRouterLink":false,"type":"github_primary","to":"https://github.com/yyjlincoln/Vue-Alerts"}},[_vm._v("Source Code")]),_c('ui-button',{attrs:{"asRouterLink":false,"type":"docs_primary","to":"https://github.com/yyjlincoln/Vue-Alerts#readme"}},[_vm._v("Check out the docs")]),_c('ui-button',{attrs:{"asRouterLink":false,"type":"external_primary","to":"https://www.npmjs.com/package/vue-alerts"}},[_vm._v("npm")]),_c('ui-button',{attrs:{"asRouterLink":false,"type":"github_secondary","to":"https://yyjlincoln.github.io/vue-alerts-demo"}},[_vm._v("Demo")]),_c('ui-button',{attrs:{"asRouterLink":false,"type":"secondary","linkClickPrevent":() => {
                _vm.$alert.present(
                  'Hello from Vue-Alerts',
                  'This is a quick demo'
                );
              },"to":""}},[_vm._v("Another Demo")])],1)],1),_c('template',{slot:"info"},[_c('div',[_c('ui-button-stack',{attrs:{"centered":false}},[_c('ui-button',{attrs:{"asRouterLink":false,"type":"github_primary","to":"https://github.com/yyjlincoln/Vue-Alerts"}},[_vm._v("Github")]),_c('ui-button',{attrs:{"asRouterLink":false,"type":"docs_primary","to":"https://github.com/yyjlincoln/Vue-Alerts#readme"}},[_vm._v("Check out the docs")]),_c('ui-button',{attrs:{"asRouterLink":false,"type":"external_primary","to":"https://www.npmjs.com/package/vue-alerts"}},[_vm._v("npm")]),_c('ui-button',{attrs:{"asRouterLink":false,"type":"github_secondary","to":"https://yyjlincoln.github.io/vue-alerts-demo"}},[_vm._v("Demo")]),_c('ui-button',{attrs:{"asRouterLink":false,"type":"secondary","linkClickPrevent":() => {
                  _vm.$alert.present(
                    'Hello from Vue-Alerts',
                    'This is a quick demo'
                  );
                },"to":""}},[_vm._v("Another Demo")])],1)],1)])],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }